import { useEffect } from "react";
import "../css/Modal.scss";
function Modal({ modalContainerRef, showModal, setShowModal }) {
    useEffect(() => {
        var submitBtn;
        var domainEmailMap = [];
        if (domainEmailMap) {
            // this is just to remove the unused var warning
        }
        var mainDomain = "https://app.ca.geta-head.com"; //"https://v2.dev.geta-head.com";
        var programRedirectUrl = mainDomain + "/app/gah"; //"https://v2.dev.geta-head.com";
        var programCode = "program-matching-sp-ontario-universities";
        var invalidEmailFormat =
            "Invalid email format. Format must follow: mail@example.com";
        var invalidEmailForProgram =
            "Invalid email address. The email you provide must belong to a Canadian university";

        //Add domains and org codes
        /*
domainEmailMap["mailinator.com"] = "z2yP";
domainEmailMap["humber.ca"] = "XYnt";
domainEmailMap["tmu.ca"] = "acbs";
domainEmailMap["geta-head.ca"] = "acbs";
domainEmailMap["geta-head.com"] = "acbs";
*/
        var apiKey = "MjXaFSz6S7Q6C1ORUfBIQdGN2xZsQJuA";
        var ouOrgCode = "Vj5F"; //"XYnt"; //OU code here

        var isProd = window.location.hostname === "youarehere.keelmind.com";
        if (!isProd) {
            mainDomain = "https://v2.dev.geta-head.com";
            apiKey = "NXn9VCmNLYjlkcv9StbDq9LnVm2dX7OL";
            ouOrgCode = "088R"; //"XYnt";
        }

        var apiUrl = mainDomain + "/api/v1/cache/store"; //"https://reqbin.com/echo/post/json"
        var gahProgramRedirect = mainDomain + "/app/gah";
        var gahDomainEmailMap = [];

        //Add domains and org codes
        /*
gahDomainEmailMap["mailinator.com"] = ouOrgCode;
gahDomainEmailMap["humber.ca"] = ouOrgCode;
gahDomainEmailMap["torontomu.ca"] = ouOrgCode;
gahDomainEmailMap["geta-head.ca"] = ouOrgCode;
gahDomainEmailMap["geta-head.com"] = ouOrgCode;
*/

        //var validDomains = ["algonquincollege.com","assumptionu.ca","uwo.ca","brocku.ca","uregina.ca","ccbc.ca","carleton.ca","centennialcollege.ca","borealc.on.ca","bdeb.qc.ca","conestogac.on.ca","op.org","dcmail.ca","fanshawec.ca","fanshaweonline.ca","gbrownc.on.ca","uoguelph.ca","humber.ca","humbermail.ca","huronuc.on.ca","uwo.ca","kingsu.ab.ca","kingston.edu","lakeheadu.ca","lambtoncollege.ca","mylambton.ca","laurentian.ca","mcmaster.ca","mohawkcollege.ca","nipissingu.ca","ocad.ca","ontariotechu.ca","ontariotechu.net","queensu.ca","redeemer.ca","rmc.ca","ryerson.ca","senecacollege.ca","myseneca.ca","sheridancollege.ca","stclairc.on.ca","stclairconnect.ca","myscc.ca","ustpaul.ca","torontomu.ca","trentu.ca","twu.ca","uoit.ca","uottawa.ca","usjc.uwaterloo.ca","utoronto.ca","usudbury.com","utoronto.ca","utm.utoronto.ca","scar.utoronto.ca","trinity.utoronto.ca","uwaterloo.ca","uwo.ca","uwindsor.ca","vicu.utoronto.ca","wlu.ca.mylaurier.ca","yorku.ca","mailinator.com","geta-head.com"];;
        var validDomains = Object.keys({
            "https://fnti.net/,ON": "First Nations Technical Institute",
            "http://www.uwo.ca/,ON": "University of Western Ontario",
            "http://www.wlu.ca/,ON": "Wilfrid Laurier University",
            "https://osttc.com/,ON":
                "Ogwehoweh Skills and Trades Training Centre",
            "http://www.ocad.ca/,ON":
                "OCAD University (Ontario College of Art and Design)",
            "https://algomau.ca/,ON": "Algoma University",
            "http://www.sl.on.ca/,ON": "St. Lawrence College",
            "http://www.yorku.ca/,ON": "York University",
            "https://uontario.ca/,ON": "Université de l'Ontario Français",
            "https://www.nosm.ca/,ON":
                "Northern Ontario School of Medicine University",
            "http://www.brocku.ca/,ON": "Brock University",
            "http://www.humber.ca/,ON": "Humber College",
            "http://www.trentu.ca/,ON": "Trent University",
            "https://www.oshki.ca/,ON":
                "Oshki-Pimache-O-Win: The Wenjack Education Institute",
            "http://www.queensu.ca/,ON": "Queen's University",
            "http://www.uhearst.ca/,ON": "Université de Hearst",
            "http://www.uottawa.ca/,ON": "University of Ottawa",
            "http://www.carleton.ca/,ON": "Carleton University",
            "http://www.mcmaster.ca/,ON": "McMaster University",
            "http://www.uoguelph.ca/,ON": "University of Guelph",
            "http://www.utoronto.ca/,ON": "University of Toronto",
            "http://www.uwindsor.ca/,ON": "University of Windsor",
            "https://shingwauku.org/,ON": "Shingwauk Kinoomaage Gamig",
            "http://www.lakeheadu.ca/,ON": "Lakehead University",
            "http://www.uwaterloo.ca/,ON": "University of Waterloo",
            "https://ontariotechu.ca/,ON": "Ontario Tech University",
            "http://www.laurentian.ca/,ON": "Laurentian University",
            "http://www.nipissingu.ca/,ON": "Nipissing University",
            "https://collegeboreal.ca/,ON": "Collège Boréal",
            "https://durhamcollege.ca/,ON": "Durham College",
            "https://iohahiio.ambe.ca/,ON":
                "Iohahi:io Akwesasne Education & Training Institute",
            "https://www.fanshawec.ca/,ON": "Fanshawe College",
            "https://www.torontomu.ca/,ON": "Toronto Metropolitan University",
            "https://flemingcollege.ca/,ON": "Fleming College",
            "http://www.georgianc.on.ca/,ON": "Georgian College",
            "https://cambriancollege.ca/,ON": "Cambrian College",
            "https://www.georgebrown.ca/,ON": "George Brown College",
            "http://www.conestogac.on.ca/,ON": "Conestoga College",
            "http://www.senecacollege.ca/,ON": "Seneca College",
            "https://aeipostsecondary.ca/,ON":
                "Anishinabek Educational Institute",
            "https://www.kenjgewinteg.ca/,ON": "Kenjgewin Teg",
            "https://www.saultcollege.ca/,ON": "Sault College",
            "https://www.7generations.org/,ON":
                "Seven Generations Education Institute",
            "https://www.collegelacite.ca/,ON": "La Cité Collégiale",
            "https://www.mohawkcollege.ca/,ON": "Mohawk College",
            "http://www.sheridancollege.ca/,ON": "Sheridan College",
            "https://www.lambtoncollege.ca/,ON": "Lambton College",
            "https://www.niagaracollege.ca/,ON": "Niagara College",
            "https://www.snpolytechnic.com/,ON": "Six Nations Polytechnic",
            "https://www.senecapolytechnic.ca/,ON": "Seneca Polytechnic",
            "https://www.stclaircollege.ca/,ON": "St. Clair College",
            "http://www.loyalistcollege.com/,ON": "Loyalist College",
            "https://www.canadorecollege.ca/,ON": "Canadore College",
            "https://www.northerncollege.ca/,ON": "Northern College",
            "http://www.algonquincollege.com/,ON": "Algonquin College",
            "http://www.centennialcollege.ca/,ON": "Centennial College",
            "https://www.confederationcollege.ca/,ON": "Confederation College",
        }).map((dom) =>
            dom.replace(/https?:\/\/(www\.)?/, "").replace("/,ON", "")
        ); // remove prefix and /,ON

        var regexDomains = [];
        const buildRegexs = function () {
            for (let dom of validDomains) {
                let regexPattern = `^[\\w.-]+@[\\w.-]*${dom.replace(
                    /\./g,
                    "\\."
                )}$`;
                let p = new RegExp(regexPattern);
                regexDomains.push(p);
            }
        };

        const checkValidEmailDomain = function (testString) {
            for (let regexTest of regexDomains) {
                //console.log(`testing ${testString} against pattern: ${regexTest}`);
                if (regexTest.test(testString)) {
                    console.log(
                        "Found match with pattern: %s",
                        regexTest.source
                    );
                    return true; //regexTest.source;
                }
            }
            return false;
        };
        /*
for (let index = 0; index < validDomains.length; ++index) {
    const element = validDomains[index];
    gahDomainEmailMap[element] = ouOrgCode;
}*/

        window.addEventListener("load", function () {
            console.log("now in the gah program connect load");
            submitBtn = document.getElementById("gah-submit-btn-2");

            if (typeof gahDomainEmailMap != "undefined") {
                domainEmailMap = gahDomainEmailMap;
            }

            if (typeof gahProgramRedirect != "undefined") {
                programRedirectUrl = gahProgramRedirect;
            }

            if (typeof window.gahProgramCode != "undefined") {
                programCode = window.gahProgramCode;
            }

            if (submitBtn != null) {
                submitBtn.addEventListener("click", submitButtonClick, false);
            }

            buildRegexs();
        });

        function submitButtonClick(ev) {
            var emailRef = "gah-email-txt";
            var errorFld = document.getElementById("gah-error-fld");
            var complianceErrorFld = document.getElementById(
                "gah-error-fld-compliance"
            );
            var checkbox = document.getElementById("gah-compliance-checkbox");

            if (!checkbox.checked) {
                complianceErrorFld.classList.add("error");
                return;
            } else {
                complianceErrorFld.classList.remove("error");
            }

            var isFormatError = false;
            if (isFormatError) {
                // this is just to remove the unused var warning
            }
            if (errorFld != null) {
                errorFld.classList.remove("error");
                errorFld.innerHTML = "";
            }

            var emailField = document.getElementById(emailRef);
            var emailValue =
                emailField != null ? emailField.value.toLowerCase() : "";

            if (validateEmailFormat(emailValue)) {
                var domainStr = emailValue.substring(
                    emailValue.lastIndexOf("@") + 1
                );
                if (domainStr) {
                    // this is just to remove the unused var warning
                }
                var objCode = ouOrgCode; //domainEmailMap.hasOwnProperty(domainStr) ? domainEmailMap[domainStr]: null;
                if (checkValidEmailDomain(emailValue)) {
                    var userObj = JSON.stringify({
                        gah_role: "client",
                        gah_program_email: emailValue,
                        gah_program_org_code: objCode,
                        gah_program_code: programCode,
                    });
                    var submitRef = "gah-submit-btn-2";
                    if (submitRef) {
                        // this is just to remove the unused var warning
                    }
                    sendGAEvent("/ouValidEmail", "appRedirect", emailValue);
                    fetchSendRequest(userObj);
                } else {
                    sendGAEvent(
                        "/ouInvalidEmail",
                        "invalidEmailForProgram",
                        emailValue
                    );
                    setError(invalidEmailForProgram);
                }
            } else {
                sendGAEvent(
                    "/ouInvalidEmail",
                    "invalidEmailFormat",
                    emailValue
                );
                setError(invalidEmailFormat);
                //alert('invalid email address!  Please correct your email');
            }
            //alert(emailField.value);
        }

        function sendGAEvent(eventName, type, emailValue) {
            if (typeof window.gtag != "undefined") {
                window.gtag("event", eventName, {
                    errorType: type,
                    emailAddress: emailValue,
                });
            }
        }

        function setError(msg) {
            var errorFld = document.getElementById("gah-error-fld");

            if (errorFld != null && !errorFld.classList.contains("error")) {
                errorFld.innerHTML = msg;
                errorFld.classList.add("error");
            }
        }

        function removeError() {
            var errorFld = document.getElementById("gah-error-fld");

            if (errorFld != null && errorFld.classList.contains("error")) {
                errorFld.innerHTML = "";
                errorFld.classList.remove("error");
            }
        }

        function validateEmailFormat(userEmail) {
            if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(userEmail)) {
                return true;
            }
            //alert("You have entered an invalid email address!")
            return false;
        }

        async function fetchSendRequest(usrObj) {
            const response = await fetch(apiUrl, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    apikey: apiKey,
                    //'Access-Control-Allow-Origin':'*',
                },
                body: `{"cache_payload": ${usrObj}}`,
            });
            if (response.ok) {
                response.json().then((data) => {
                    console.log("from fetch");
                    console.log(data);
                    redirectUrl(data);
                });
            }
        }

        function redirectUrl(guid) {
            window.location.href =
                programRedirectUrl + "/#/programconnect/" + guid;
        }

        //reset error msg
        var elements = document.getElementsByClassName("close-icon");

        for (var i = 0; i < elements.length; i++) {
            elements[i].addEventListener("click", removeError, false);
        }

        window.addEventListener("load", function () {
            var gahForms = document.getElementsByClassName("gah-form");
            if (gahForms.length > 0) {
                var sqsGahEl = gahForms[0].closest(".sqs-block-content");
                if (sqsGahEl != null) {
                    sqsGahEl.classList.add("gah-flex");
                }
            }
        });
    }, []);

    function handleEscape(event) {
        if (event.keyCode === 27) {
            event.preventDefault();
            setShowModal(false);
        }
    }

    // function handleCheckbox(e) {
    //     document.getElementById("gah-submit-btn-2").disabled =
    //         !e.target.checked;
    // }

    return (
        <div
            className={"modal-container" + (showModal ? " show" : "")}
            ref={modalContainerRef}
            onKeyDown={handleEscape}
        >
            <div className="back-cover"></div>
            <div className="modal">
                <div
                    className="close-button"
                    aria-label="Close sign up modal"
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                        setShowModal(false);
                    }}
                    onKeyDown={(event) => {
                        if (event.keyCode === 13) {
                            event.preventDefault();
                            setShowModal(false);
                        }
                    }}
                >
                    <img src="/x-icon.svg" alt="" />
                </div>
                <h2>Start your journey</h2>
                <p>
                    Connect to a mental health care provider today at no cost to
                    you. Your school email is used for signup purposes only. Any
                    sessions or notes taken on the platform{" "}
                    <strong>will not</strong> be shared with your school.
                </p>

                <label className="modal-form-label">
                    <input
                        className="gah-email-fld"
                        type="text"
                        id="gah-email-txt"
                        placeholder="Enter school email..."
                    />
                    <strong className="label-text">School Email</strong>
                </label>
                <div id="gah-error-fld" className="gah-error-fld">
                    Invalid email address. The email you provide must belong to
                    a Canadian university
                </div>
                <label className="modal-form-label horizontal-label">
                    <input
                        // onInput={handleCheckbox}
                        type="checkbox"
                        name=""
                        id="gah-compliance-checkbox"
                    />
                    <p>
                        I accept the{" "}
                        <a href="https://keelmind.com/terms-of-use">
                            terms of use
                        </a>{" "}
                        and{" "}
                        <a href="https://keelmind.com/privacy-policy">
                            privacy policy
                        </a>
                        .
                    </p>
                </label>
                <div id="gah-error-fld-compliance" className="gah-error-fld">
                    You must accept the terms of use and privacy policy.
                </div>
                <div className="javascript">
                    <button
                        href="/#"
                        id="gah-submit-btn-2"
                        className="cta-button"
                    >
                        Get started
                    </button>
                </div>

                <p className="p-tiny">
                    This program is funded by the Ministry of Colleges and
                    Universities and is intended for Ontario postsecondary
                    students only. If you are not an Ontario postsecondary
                    student and would like to learn more about this program or
                    Keel Mind, please{" "}
                    <a
                        href="https://www.keelmind.com/contact-us"
                        className="orange"
                    >
                        contact us
                    </a>
                </p>
            </div>
        </div>
    );
}

export default Modal;
